import { useState } from "react";
import { Link } from "react-router-dom";
import { login, googleAuth } from "./../utils/api";
import {
  Button,
  Alert,
  TextField,
  Divider,
  Typography,
  LinearProgress,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useAuth } from "../utils/auth";
import styled from "@emotion/styled";
import PasswordInput from "./PasswordInput";
import { GoogleLogin } from "@react-oauth/google";

export default function LoginForm() {
  const theme = useTheme();
  const { updateAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [socialLoginLoading, setSocialLoginLoading] = useState(false);
  const [emailLoginLoading, setEmailLoginLoading] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setError("");
    if (!email) {
      setError("Adresse e-mail invalide");
      return;
    }

    if (!password) {
      setError("Mot de passe invalide");
      return;
    }
    setEmailLoginLoading(true);
    login(email, password)
      .then((res) => {
        updateAuth(res.data);
      })
      .catch((err) => {
        setError("Adresse e-mail ou mot de passe invalide.");
      })
      .finally(() => {
        setEmailLoginLoading(false);
      });
  };

  const onGoogleLoginSuccess = (tokenResponse) => {
    setSocialLoginLoading(true);
    setError("");
    googleAuth(tokenResponse.credential)
      .then((res) => {
        updateAuth(res.data);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setError("L'adresse e-mail est déjà utilisée par un autre compte.");
        } else {
          setError("Erreur lors de la connexion avec Google.");
        }
      })
      .finally(() => {
        setSocialLoginLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextField
          label="Adresse e-mail"
          variant="standard"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          value={email}
          name="email"
          fullWidth
        />
        <PasswordInput
          label="Mot de passe"
          variant="standard"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          fullWidth
          name="password"
          style={{ marginTop: theme.spacing(2) }}
        />

        {!emailLoginLoading && !socialLoginLoading && (
          <Button
            style={{ marginTop: theme.spacing(3) }}
            variant="outlined"
            type="submit"
            disabled={socialLoginLoading || emailLoginLoading}
          >
            Se connecter
          </Button>
        )}

        {emailLoginLoading && (
          <LinearProgress sx={{ marginTop: 2, height: "2px" }} />
        )}
      </form>

      {!!error && (
        <Alert style={{ marginTop: theme.spacing(2) }} severity="error">
          {error}
        </Alert>
      )}

      {!emailLoginLoading && !socialLoginLoading && (
        <>
          <div
            style={{
              marginTop: theme.spacing(2),
            }}
          >
            <Link
              to="reset-password"
              style={{
                font: "inherit",
                color: "black",
                textDecoration: "none",
                fontSize: "0.9rem",
              }}
            >
              <ResetPasswordLink>Mot de passe oublié ?</ResetPasswordLink>
            </Link>
          </div>
          <Divider sx={{ marginTop: theme.spacing(2) }}>
            <Typography variant="body2" color="textSecondary">
              Connectez vous avec
            </Typography>
          </Divider>
          <div
            style={{
              marginTop: theme.spacing(2),
            }}
          >
            <GoogleLogin
              onSuccess={onGoogleLoginSuccess}
              onError={() =>
                setError("Erreur lors de la connexion avec Google.")
              }
              type="icon"
              shape="circle"
              ux_mode="popup"
            />
          </div>
        </>
      )}

      {socialLoginLoading && (
        <LinearProgress sx={{ marginTop: 2, height: "2px" }} />
      )}
    </>
  );
}

const ResetPasswordLink = styled.span`
  &:hover {
    text-decoration: underline;
  }
`;
