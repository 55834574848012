import { useState } from "react";

export default function Image({ alt, ...props }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      alt={alt}
      {...props}
      style={loaded ? { ...props.style } : { display: "none", ...props.style }}
      onLoad={() => setLoaded(true)}
    />
  );
}
