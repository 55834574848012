import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import React, { useEffect, useState } from "react";
import COLORS from "../../utils/colors";
import { useMediaQuery } from "@mui/material";

export default function PieGraph({ event, pointsData }) {
  const [points, setPoints] = useState(null);
  const largeScreen = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    let tmpPoints = {};

    event.members.forEach((user) => {
      tmpPoints[user.username] = 0;
    });

    pointsData.forEach((point) => {
      tmpPoints[point.from_member.username] += Math.abs(
        point.transaction_amount
      );
    });

    setPoints(
      Object.entries(tmpPoints).map((entry) => ({
        username: entry[0],
        value: entry[1],
      }))
    );
  }, [event, pointsData]);

  if (points === null) {
    return <></>;
  }

  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      <PieChart>
        <Pie
          data={points}
          dataKey="value"
          nameKey="username"
          cx="50%"
          cy="50%"
          outerRadius={largeScreen ? 170 : 140}
          fill="#8884d8"
        >
          {points.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}
