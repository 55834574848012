import { useEffect, useState } from "react";
import { getEvents } from "../utils/api";
import { Grid, LinearProgress, Typography } from "@mui/material";
import GroupCard from "../Components/GroupCard";

import PageContainer from "./../Components/PageContainer";

export default function Home() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEvents().then((res) => {
      setEvents(res.data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress sx={{ height: "3px" }} />;
  }

  if (events.length === 0) {
    return (
      <PageContainer>
        <Typography>
          Tu n'as pas encore de groupe ! Crée ton premier groupe (avec le gros
          bouton vert (+) en bas du menu) et invite tes potes !
        </Typography>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Grid container spacing={2}>
        {events.map((event) => (
          <Grid item xs={12} lg={6} xl={4} key={event.eventId}>
            <GroupCard group={event} />
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
}
