import { useTheme } from "@mui/material/styles";
import { getUsersInfo, getEventsInfo, getLastActivity } from "../utils/api";
import { useEffect, useState } from "react";
import { Alert, Typography, LinearProgress } from "@mui/material";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import PageContainer from "../Components/PageContainer";

export default function Admin() {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [events, setEvents] = useState(null);
  const [lastPointTrasactions, setLastPointTransactions] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    Promise.all([
      getEventsInfo().then((res) => Promise.resolve(res.data)),
      getUsersInfo().then((res) => Promise.resolve(res.data)),
      getLastActivity().then((res) => Promise.resolve(res.data)),
    ])
      .then(([eventData, userData, activityData]) => {
        setUsers(userData.users_info);
        setEvents(eventData.events);
        setLastPointTransactions(activityData);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const userColumns = [
    { field: "username", headerName: "username", width: 200 },
    { field: "created_at", headerName: "created_at", width: 200 },
    { field: "n_groups", headerName: "n_groups", width: 200 },
    {
      field: "n_transactions_from",
      headerName: "n_transactions_from",
      width: 200,
    },
    { field: "n_transactions_to", headerName: "n_transactions_to", width: 200 },
  ];

  const eventColumns = [
    { field: "name", headerName: "name", width: 200 },
    { field: "created_at", headerName: "created_at", width: 200 },
  ];

  const pointTransactionColumns = [
    { field: "timestamp", headerName: "timestamp", width: 200 },
    {
      field: "transaction_amount",
      headerName: "transaction_amount",
      width: 200,
    },
    { field: "event", headerName: "event", width: 200 },
    { field: "from_user", headerName: "from_user", width: 200 },
    { field: "to_user", headerName: "to_user", width: 200 },
  ];
  if (loading) {
    return <LinearProgress sx={{ height: "3px" }} />;
  }

  if (error) {
    return (
      <PageContainer>
        <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
          Désolé, une erreur est survenue
        </Alert>
      </PageContainer>
    );
  }
  return (
    <>
      <PageContainer>
        <Typography variant="h1" style={{ marginTop: theme.spacing(2) }}>
          Users
        </Typography>

        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            style={{ marginTop: "20px" }}
            rows={users.map((user) => ({
              id: user.userId,
              ...user,
              n_groups: user.n_events,
              created_at: moment(user.created_at).format("DD/MM/YY HH:mm"),
            }))}
            columns={userColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </div>

        <Typography variant="h1" style={{ marginTop: theme.spacing(2) }}>
          Groups
        </Typography>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            style={{ marginTop: "20px" }}
            rows={events.map((event) => ({
              id: event.eventId,
              ...event,
              created_at: moment(event.created_at).format("DD/MM/YY HH:mm"),
            }))}
            columns={eventColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </div>
        <Typography variant="h1" style={{ marginTop: theme.spacing(2) }}>
          Last Point Transactions
        </Typography>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            style={{ marginTop: "20px" }}
            rows={lastPointTrasactions.map((pointTransaction) => ({
              id: pointTransaction.transactionId,
              ...pointTransaction,
              event: pointTransaction.event.name,
              from_user: pointTransaction.from_user.username,
              to_user: pointTransaction.to_user.username,
              timestamp: moment(pointTransaction.timestamp).format(
                "DD/MM/YY HH:mm"
              ),
            }))}
            columns={pointTransactionColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </div>
      </PageContainer>
    </>
  );
}
