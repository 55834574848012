import { useState } from "react";
import { Navigate } from "react-router-dom";
import Header from "./../Components/Header";
import { useAuth } from "../utils/auth";
import { TextField, Button, Typography, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { sendPasswordResetEmail } from "../utils/api";
import PageContainer from "../Components/PageContainer";

export default function RequestPasswordReset() {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { authenticated } = useAuth();

  if (authenticated) {
    return <Navigate to="/change-password" />;
  }

  const submit = (e) => {
    e.preventDefault();
    setSuccess(false);
    if (!email || !email.match("^(.+)@(.+)$")) {
      setError(true);
      return;
    }

    setError(false);
    sendPasswordResetEmail(email).finally(() => {
      setSuccess(true);
    });
  };

  return (
    <>
      <Header />
      <PageContainer size={"small"}>
        <Typography variant="h1">Réintialiser votre mot de passe</Typography>
        <form onSubmit={submit}>
          <TextField
            variant="standard"
            label="Adresse e-mail:"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            style={{ marginTop: theme.spacing(4) }}
            disabled={success}
          />
          <Button
            style={{ marginTop: theme.spacing(4) }}
            variant="outlined"
            type="submit"
            disabled={success}
          >
            Réintialiser
          </Button>
        </form>

        {error && (
          <Alert severity="error" style={{ marginTop: theme.spacing(4) }}>
            Adresse e-mail invalide.
          </Alert>
        )}
        {success && (
          <Alert severity="success" style={{ marginTop: theme.spacing(4) }}>
            Un lien de réinitialisation a été envoyé à l'adresse e-mail
            indiquée.
          </Alert>
        )}
      </PageContainer>
    </>
  );
}
