import { useTheme } from "@mui/material/styles";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Cell,
  ReferenceLine,
  Tooltip,
  Rectangle,
} from "recharts";
import React from "react";
import { Box } from "@mui/material";

export default function EventSummaryBarChart({ pointsData }) {
  const theme = useTheme();
  const nUsers = pointsData.length;
  if (pointsData === null || nUsers === 0) {
    return <></>;
  }

  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      <BarChart
        data={pointsData
          .sort((a, b) => b.total_points - a.total_points)
          .map((point, index) => ({
            ...point,
            rank: nUsers - index,
            usernameShort:
              point.username.length < 10
                ? point.username
                : point.username.slice(0, 8) + "..",
          }))}
        // layout="vertical"
        margin={{
          top: 20,
          right: 0,
          left: -40,
          bottom: 20,
        }}
        style={{ cursor: "pointer" }}
      >
        <XAxis
          type="category"
          dataKey="usernameShort"
          axisLine={false}
          tick={nUsers > 10 ? false : true}
          minTickGap={nUsers > 10 ? 0 : 5}
        />
        <YAxis
          type="number"
          domain={[
            Math.min(0, ...pointsData.map((point) => point.total_points - 1)),
            Math.max(0, ...pointsData.map((point) => point.total_points + 1)),
          ]}
          tick={false}
          axisLine={false}
        />
        <ReferenceLine y={0} stroke="#000" />
        <Bar
          dataKey="total_points"
          fill={theme.palette.primary.light}
          isAnimationActive={false}
        >
          <LabelList dataKey="total_points" position="top" />
          {pointsData.map((datum, entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                datum.total_points >= 0
                  ? theme.palette.primary.light
                  : theme.palette.error.light
              }
            />
          ))}
        </Bar>
        <Tooltip cursor={<CustomCursor />} content={<CustomTooltip />} />
      </BarChart>
    </ResponsiveContainer>
  );
}

const CustomCursor = (props) => {
  const theme = useTheme();
  const { x, y, width, height, payload } = props;
  return (
    <Rectangle
      fill={
        payload[0].value >= 0
          ? theme.palette.primary.main
          : theme.palette.error.light
      }
      strokeWidth={2}
      x={x + width / 2}
      y={y}
      width={2}
      height={height}
    />
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "3px",
          border: "1px solid",
          borderColor: theme.palette.primary.main,
          padding: theme.spacing(2),
        }}
      >
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
        </div>
      </Box>
    );
  }

  return null;
};
