import React from "react";

export const AuthContext = React.createContext({
  authenticated: false,
  updateAuth: () => {},
  currentUserId: null,
  emailConfirmed: null,
  setEmailConfirmed: () => {},
  isAdmin: false,
});

export const useAuth = () => React.useContext(AuthContext);
