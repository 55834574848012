import React from "react";
import { Box, Typography } from "@mui/material";
import PageContainer from "../Components/PageContainer";
import Header from "../Components/Header";

const Title = ({ children }) => {
  return (
    <Typography variant="h5" component="h2">
      {children}
    </Typography>
  );
};

const Subtitle = ({ children }) => {
  return (
    <Typography variant="h6" component="h3" sx={{ paddingTop: 2 }}>
      {children}
    </Typography>
  );
};

const Paragraph = ({ children }) => {
  return (
    <Typography variant="body1" sx={{ paddingTop: 2 }}>
      {children}
    </Typography>
  );
};

function PrivacyPolicy() {
  return (
    <Box
      sx={{ maxHeight: "100vh", overflow: "scroll", scrollBehavior: "smooth" }}
    >
      <Header displayHamburger={false} />
      <PageContainer size={"small"}>
        <Title>Politique de confidentialité.</Title>
        <Paragraph>
          Cette politique de confidentialité décrit comment nous recueillons,
          utilisons, traitons et divulguons vos informations, y compris les
          informations personnelles, en relation avec votre accès et
          l'utilisation de <i>Note Tes Potes.</i>.
        </Paragraph>
        <Subtitle>Collecte de l'information</Subtitle>
        <Paragraph>
          Lors de l'utilisation de notre application, nous pouvons vous demander
          de nous fournir certaines informations personnelles identifiables qui
          peuvent être utilisées pour vous contacter ou vous identifier. Ces
          informations peuvent comprendre, mais ne sont pas limitées à, votre
          profil public Google et votre adresse e-mail que vous fournissez
          lorsque vous utilisez l'option de connexion Google.
        </Paragraph>

        <Subtitle>Utilisation des informations</Subtitle>
        <Paragraph>
          Nous utiliserons ces informations pour fournir et améliorer notre
          service, pour vous identifier et/ou vous contacter, pour répondre à
          vos demandes/enquêtes, pour surveiller l'utilisation de notre service
          et pour détecter, prévenir et résoudre les problèmes techniques.
        </Paragraph>

        <Subtitle>Partage et divulgation</Subtitle>
        <Paragraph>
          Excepté que décrit dans cette politique, nous ne partagerons jamais
          vos informations personnelles avec des tiers sans votre permission.
          Vos informations personnelles ne seront utilisées que pour les raisons
          indiquées dans la politique de confidentialité, sauf si autrement
          requis par la loi.
        </Paragraph>
        <Subtitle>Sécurité de vos informations</Subtitle>
        <Paragraph>
          Nous nous efforçons d'utiliser des moyens de protection
          commercialement acceptables pour protéger vos informations
          personnelles, mais nous ne pouvons garantir leur sécurité absolue.
          Aucune méthode de transmission sur Internet, ou de stockage
          électronique, n'est sécurisée à 100%.
        </Paragraph>
        <Subtitle>Suppression des données</Subtitle>
        <Paragraph>
          Lorsque vous supprimez votre compte, toutes vos données personnelles
          sont également supprimées de nos systèmes. Cela comprend les
          informations de profil, les adresses e-mail et toute autre information
          que nous avons recueillie lors de votre utilisation de notre service.
          Veuillez noter que la suppression de vos données est irréversible et
          que nous ne pourrons pas récupérer vos données une fois votre compte
          supprimé.
        </Paragraph>

        <Subtitle>Délai de conservation des données</Subtitle>
        <Paragraph>
          Nous conservons vos informations personnelles aussi longtemps que
          votre compte est actif. Si vous choisissez de supprimer votre compte,
          toutes vos informations personnelles sont supprimées immédiatement.
          Nous ne conservons pas vos informations personnelles au-delà de la
          suppression de votre compte.
        </Paragraph>
        <Subtitle>Modifications de cette politique de confidentialité</Subtitle>
        <Paragraph>
          Nous nous réservons le droit de modifier cette politique de
          confidentialité à tout moment. Nous vous informerons de tout
          changement en publiant la nouvelle politique de confidentialité sur
          cette page. Vous êtes conseillé de consulter régulièrement cette
          politique de confidentialité pour tout changement.
        </Paragraph>
        <Box sx={{ paddingTop: 3 }}>
          <Typography variant="caption">
            Date d'effet : 3 Octobre 2023
          </Typography>
        </Box>
      </PageContainer>
    </Box>
  );
}

export default PrivacyPolicy;
