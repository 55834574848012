import React from "react";
import { Box, Typography } from "@mui/material";
import PageContainer from "../Components/PageContainer";
import Header from "../Components/Header";

const Title = ({ children }) => {
  return (
    <Typography variant="h5" component="h2">
      {children}
    </Typography>
  );
};

const Subtitle = ({ children }) => {
  return (
    <Typography variant="h6" component="h3" sx={{ paddingTop: 2 }}>
      {children}
    </Typography>
  );
};

const Paragraph = ({ children }) => {
  return (
    <Typography variant="body1" sx={{ paddingTop: 2 }}>
      {children}
    </Typography>
  );
};

function TermsOfService() {
  return (
    <Box
      sx={{ maxHeight: "100vh", overflow: "scroll", scrollBehavior: "smooth" }}
    >
      <Header displayHamburger={false} />
      <PageContainer size={"small"}>
        <Title>CONDITIONS D'UTILISATION DE "NOTE TES POTES"</Title>
        <Subtitle>1. INTRODUCTION</Subtitle>
        <Paragraph>
          1.1. Ces Conditions d'utilisation ("Conditions") régissent votre
          utilisation de l'application Web "Note Tes Potes." (l' "Application"),
          fourni par notre société ("nous", "nos", "notre"). En utilisant notre
          Application, vous acceptez ces Conditions.
        </Paragraph>
        <Paragraph>
          1.2. Si vous n’acceptez pas ces Conditions, vous ne devez pas utiliser
          notre Application.
        </Paragraph>
        <Subtitle>2. VOTRE COMPTE</Subtitle>
        <Paragraph>
          2.1. Pour utiliser notre Application, vous devrez créer un compte.
          Vous devez fournir des informations exactes et complètes lors de la
          création de votre compte.
        </Paragraph>
        <Paragraph>
          2.2. Vous êtes responsable de toutes les activités qui se produisent
          sous votre compte. Si vous découvrez une activité non autorisée sur
          votre compte, vous devez nous en informer immédiatement.
        </Paragraph>

        <Subtitle>3. UTILISATION DE NOTRE APPLICATION</Subtitle>
        <Paragraph>
          3.1. Vous ne devez pas utiliser notre Application de manière illégale
          ou nuisible, ou de toute manière qui pourrait endommager notre
          propriété ou notre réputation.
        </Paragraph>
        <Paragraph>
          3.2. Vous devez respecter toutes les lois applicables lors de
          l'utilisation de notre Application. Vous ne devez pas:
          <ul>
            <li>
              Charger, partager ou envoyer du contenu illégal, offensant ou
              inapproprié via notre Application.
            </li>
            <li>
              Violer les droits d'auteur, les droits de marque ou autres droits
              de propriété intellectuelle.
            </li>
          </ul>
        </Paragraph>

        <Subtitle>4. PROPRIÉTÉ INTELLECTUELLE</Subtitle>
        <Paragraph>
          4.1. Nous détenons tous les droits sur notre Application. Vous ne
          pouvez pas utiliser notre nom, notre marque de commerce, notre logo ou
          notre propriété intellectuelle sans notre autorisation écrite
          préalable.
        </Paragraph>
        <Subtitle>5. RÉSILIATION </Subtitle>
        <Paragraph>
          5.1. Nous pouvons résilier ou suspendre votre accès à notre
          Application à tout moment, sans préavis, pour une raison quelconque, y
          compris, sans s'y limiter, si vous violez ces Conditions.
        </Paragraph>
        <Subtitle>6. MODIFICATIONS DES CONDITIONS</Subtitle>
        <Paragraph>
          6.1. Nous pouvons modifier ces Conditions à tout moment. Les
          modifications prendront effet dès leur publication sur notre
          Application.
        </Paragraph>
        <Subtitle>7. LOI APPLICABLE</Subtitle>
        <Paragraph>
          7.1. Ces Conditions sont régies par le droit français. Vous acceptez
          de vous soumettre à la juridiction exclusive des tribunaux français
          pour résoudre tout litige découlant de ces Conditions.
        </Paragraph>
        <Box sx={{ paddingTop: 3 }}>
          <Typography variant="caption">
            Merci d'avoir choisi Note Tes Potes.
          </Typography>
        </Box>
      </PageContainer>
    </Box>
  );
}

export default TermsOfService;
