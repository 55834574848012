import ColoredPageContainer from "../Components/ColoredPageContainer";
import LoginForm from "../Components/LoginForm";
import RegisterForm from "../Components/RegisterForm";

import { Card, CardContent, Modal, Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LandingPage() {
  const smallScreen = useMediaQuery("(max-width:600px)");
  const largeScreen = useMediaQuery("(min-width:1200px)");
  const theme = useTheme();
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);
  return (
    <ColoredPageContainer>
      <Modal
        open={registerModalIsOpen}
        onClose={() => setRegisterModalIsOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: smallScreen ? "95%" : "475px",
            maxWidth: "475px",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Box sx={{ padding: theme.spacing(2) }}>
            <RegisterForm />
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          paddingTop: largeScreen ? "12%" : "8%",
          paddingBottom: theme.spacing(2),
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} lg={6}>
            <div
              style={{
                textAlign: largeScreen ? "right" : "center",
                marginBottom: theme.spacing(4),
                padding: largeScreen ? "12% 15% 0 0" : theme.spacing(1),
              }}
            >
              <Title>Note Tes Potes.</Title>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div
              style={{
                width: smallScreen ? "95%" : "475px",
                maxWidth: "475px",
                margin: largeScreen ? "0" : "0 auto",
              }}
            >
              <Card>
                <CardContent>
                  <LoginForm />
                </CardContent>
              </Card>
              <Card style={{ marginTop: theme.spacing(1) }}>
                <CardContent>
                  Ou{" "}
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      textDecoration: "underline",
                      padding: 0,
                      font: "inherit",
                      cursor: "pointer",
                      color: "black",
                    }}
                    onClick={() => setRegisterModalIsOpen(true)}
                  >
                    créez un nouveau compte !
                  </button>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Box>
    </ColoredPageContainer>
  );
}

//DEF5E5
//BCEAD5
//BCEAD5
//8EC3B0

//EA047E
//FF6D28
//FCE700
//00F5FF

function Title({ children }) {
  const theme = useTheme();
  const smallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Typography
      variant="h1"
      style={{
        ...theme.typography.title,
        fontSize: smallScreen ? 40 : 56,
        marginTop: theme.spacing(4),
      }}
    >
      {children}
    </Typography>
  );
}
