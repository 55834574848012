import { Button, Typography, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import Header from "../Components/Header";
import PageContainer from "../Components/PageContainer";
import { sendEmailConfirmEmail } from "../utils/api";

export default function EmailNotConfirmed() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const theme = useTheme();

  const sendConfirmationEmail = () => {
    setDisabled(true);
    sendEmailConfirmEmail()
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <>
      <Header />
      <PageContainer>
        <Typography variant="h1">Confirmez votre adresse e-mail.</Typography>
        <p>
          Merci de vérifier votre adresse email en cliquant sur le lien reçu par
          mail.
        </p>
        <p>Vous n'avez pas reçu l'email de confirmation ?</p>
        <Button
          variant="outlined"
          onClick={sendConfirmationEmail}
          disabled={disabled}
        >
          Renvoyer le mail
        </Button>

        {success && (
          <Alert style={{ marginTop: theme.spacing(2) }} severity="success">
            Nous vous avons renvoyé le mail de confirmation. Regardez dans vos
            spams si vous ne le trouvez pas dans votre boite de réception.
          </Alert>
        )}
        {error && (
          <Alert style={{ marginTop: theme.spacing(2) }} severity="error">
            Une erreur est survenue, désolé.
          </Alert>
        )}
      </PageContainer>
    </>
  );
}
