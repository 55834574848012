import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../utils/auth";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

export default function Header({ onMenuClick, displayHamburger = true }) {
  const theme = useTheme();
  const { authenticated, emailConfirmed } = useAuth();

  const isAuthenticated = authenticated && emailConfirmed;

  return (
    <AppBar position="sticky">
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar>
          {isAuthenticated && displayHamburger && (
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={onMenuClick}
            >
              <MenuIcon />
            </IconButton>
          )}

          {isAuthenticated ? (
            <div
              style={{
                ...theme.typography.title,
                flexGrow: 1,
                fontSize: 24,
                verticalAlign: "middle",
                height: "48px",
                lineHeight: "48px",
              }}
            >
              Note Tes Potes.
            </div>
          ) : (
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <div
                style={{
                  ...theme.typography.title,
                  flexGrow: 1,
                  fontSize: 24,
                  verticalAlign: "middle",
                  height: "48px",
                  lineHeight: "48px",
                }}
              >
                Note Tes Potes.
              </div>
            </Link>
          )}
        </Toolbar>
      </Box>
    </AppBar>
  );
}
