import { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Header from "./../Components/Header";
import { useAuth } from "../utils/auth";
import { TextField, Button, Typography, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { resetPassword } from "../utils/api";
import PageContainer from "../Components/PageContainer";

export default function PasswordReset() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { authenticated } = useAuth();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  if (authenticated) {
    return <Navigate to="/change-password" />;
  }

  const handleChange = (e) => {
    setFormData((previousData) => ({
      ...previousData,
      [e.target.name]: e.target.value,
    }));
  };

  const submit = (e) => {
    e.preventDefault();
    setSuccess(false);
    if (!formData.email) {
      setError("Adresse email invalide.");
      return;
    }

    if (
      !formData.password ||
      !formData.password.match(
        "^(?=.*[0-9])(?=.*[-!@#$^&*])[a-zA-Z0-9-!@#$^&*]{6,30}$"
      )
    ) {
      setError(
        "Le mot de passe est invalide. Votre mot de passe doit comporter entre 6 et 30 caractères, " +
          "et inclure au moins un chiffre et un caractère spécial (-!@#$^&*)."
      );
      return;
    }

    if (formData.password !== formData.passwordConfirm) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    setError(false);
    resetPassword(formData.email, formData.password, token)
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch(() => {
        setError("Désolé, nous n'avons pas pu mettre à jour le mot de passe.");
      });
  };

  return (
    <>
      <Header />
      <PageContainer size={"small"}>
        <Typography variant="h1">Réintialiser votre mot de passe</Typography>
        <form onSubmit={submit} onChange={handleChange}>
          <TextField
            variant="standard"
            label="Adresse e-mail"
            name="email"
            fullWidth
            disabled={success}
          />
          <TextField
            variant="standard"
            label="Nouveau mot de passe"
            name="password"
            type="password"
            fullWidth
            style={{ marginTop: theme.spacing(4) }}
            disabled={success}
          />
          <TextField
            variant="standard"
            label="Confirmer le mot de passe"
            name="passwordConfirm"
            type="password"
            fullWidth
            style={{ marginTop: theme.spacing(4) }}
            disabled={success}
          />
          <Button
            style={{ marginTop: theme.spacing(4) }}
            variant="outlined"
            type="submit"
            disabled={success}
          >
            Réintialiser
          </Button>
        </form>

        {error && (
          <Alert severity="error" style={{ marginTop: theme.spacing(4) }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" style={{ marginTop: theme.spacing(4) }}>
            Le mot de passe a été mis à jour. Vous allez être redirigé vers la
            page de connexion.
          </Alert>
        )}
      </PageContainer>
    </>
  );
}
