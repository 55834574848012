import { useTheme } from "@emotion/react";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  Stack,
  Box,
  useMediaQuery,
  Skeleton,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import PageContainer from "../Components/PageContainer";
import AudioPlayer from "../Components/AudioPlayer";
import { useAuth } from "../utils/auth";
import Image from "./Image";

export default function GroupHistory({ event, pointsData }) {
  const largeScreen = useMediaQuery("(min-width:1000px)");
  const smallScreen = useMediaQuery("(max-width:500px)");
  const theme = useTheme();
  const { currentUserId } = useAuth();
  const [points, setPoints] = useState(null);
  const [selectedSender, setSelectedSender] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(currentUserId);
  const [imageDialogProps, setImageDialogProps] = useState({
    open: false,
    imageUrl: null,
  });

  useEffect(() => {
    let pointsDataSorted = [...pointsData];
    pointsDataSorted = pointsData.sort(
      (a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf()
    );

    setPoints(pointsDataSorted);
  }, [event, pointsData, selectedSender, selectedRecipient]);

  if (!pointsData) {
    return (
      <PageContainer>
        <FakeFeed />
      </PageContainer>
    );
  }

  if (points === null || points.length === 0) {
    return (
      <PageContainer>
        <Typography>Il n'y a encore rien à afficher ici.</Typography>
      </PageContainer>
    );
  }

  function formatTimeDiff(ts) {
    let diff = moment.duration(moment().diff(moment(ts)));

    if (diff.asDays() >= 1) {
      return Math.round(diff.asDays()) + " j";
    }

    if (diff.asHours() >= 1) {
      return Math.round(diff.asHours()) + " h";
    }

    return Math.round(diff.asMinutes()) + " m";
  }

  const onSelectedSenderChange = (e) => {
    if (e.target.value === selectedRecipient) {
      setSelectedRecipient(null);
    }
    setSelectedSender(e.target.value === "ALL" ? null : e.target.value);
  };
  const onSelectedRecipientChange = (e) => {
    if (e.target.value === selectedSender) {
      setSelectedSender(null);
    }
    setSelectedRecipient(e.target.value === "ALL" ? null : e.target.value);
  };

  function feedMessage(transaction) {
    if (transaction.to_member.userId === currentUserId) {
      return `${transaction.from_member.username} t'a attribué ${transaction.transaction_amount} points.`;
    }
    if (transaction.from_member.userId === currentUserId) {
      return `Tu as attribué ${transaction.transaction_amount} points à ${transaction.to_member.username}.`;
    } else {
      return `${transaction.from_member.username} a attribué ${transaction.transaction_amount} points à ${transaction.to_member.username}.`;
    }
  }

  return (
    <Box>
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Envoyeur</InputLabel>

          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={selectedSender || "ALL"}
            onChange={onSelectedSenderChange}
          >
            <MenuItem value={"ALL"}>Tous</MenuItem>
            {event.members
              .sort((a, b) => a.username.localeCompare(b.username))
              .map((user) => (
                <MenuItem value={user.userId} key={user.userId}>
                  {user.username}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Destinataire</InputLabel>

          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={selectedRecipient || "ALL"}
            onChange={onSelectedRecipientChange}
          >
            <MenuItem value={"ALL"}>Tous</MenuItem>
            {event.members
              .sort((a, b) => a.username.localeCompare(b.username))
              .map((user) => (
                <MenuItem value={user.userId} key={user.userId}>
                  {user.username}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2} sx={{}}>
        {pointsData
          .filter((transaction) =>
            selectedSender === "ALL" || selectedSender === null
              ? true
              : transaction.from_member.userId === selectedSender
          )
          .filter((transaction) =>
            selectedRecipient === "ALL" || selectedRecipient === null
              ? true
              : transaction.to_member.userId === selectedRecipient
          )
          .map((transaction) => (
            <Grid item xs={12} key={transaction.transactionId}>
              <Card sx={{ marginBottom: theme.spacing(3) }}>
                <CardContent>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      marginBottom: theme.spacing(2),
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar>
                      {transaction.from_member.username
                        .slice(0, 2)
                        .toUpperCase()}
                    </Avatar>
                    <div>
                      <Typography
                        sx={{ fontSize: largeScreen ? 16 : 13 }}
                        component="div"
                      >
                        {feedMessage(transaction)}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }} color="text.secondary">
                        {formatTimeDiff(transaction.timestamp)}
                      </Typography>
                    </div>
                  </Stack>

                  <Box sx={{ marginLeft: theme.spacing(1) }}>
                    <Typography
                      sx={{ fontSize: 18 }}
                      component="p"
                      color="text.secondary"
                    >
                      {transaction.message}
                    </Typography>
                  </Box>
                </CardContent>

                {!!transaction.audio && (
                  <CardActions>
                    <Box
                      sx={{
                        width: "100%",
                        padding: theme.spacing(1),
                      }}
                    >
                      <AudioPlayer
                        src={transaction.audio}
                        sx={{ maxWidth: smallScreen ? "none" : "300px" }}
                      />
                    </Box>
                  </CardActions>
                )}
                {!!transaction.image && (
                  <Box
                    sx={{
                      maxHeight: "600px",
                      maxWidth: smallScreen ? "100%" : "50%",
                      margin: theme.spacing(2),
                      border: "1px solid",
                      borderColor: theme.palette.primary.main,
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      src={transaction.image}
                      alt="Uploaded"
                      style={{
                        objectFit: "contain",
                        display: "block",
                        maxWidth: "100%",
                      }}
                      onClick={() =>
                        setImageDialogProps({
                          open: true,
                          imageUrl: transaction.image,
                        })
                      }
                    />
                  </Box>
                )}
              </Card>
            </Grid>
          ))}
      </Grid>
      <ImageDialog
        {...imageDialogProps}
        handleClose={() => {
          setImageDialogProps({ open: false, imageUrl: null });
        }}
      />
    </Box>
  );
}

function FakeFeed() {
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ marginBottom: theme.spacing(3) }}>
            <CardContent>
              <Stack>
                <Skeleton height={"30px"} />
                <Skeleton height={"30px"} width={"50%"} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ marginBottom: theme.spacing(3) }}>
            <CardContent>
              <Stack>
                <Skeleton height={"30px"} />
                <Skeleton height={"30px"} width={"50%"} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

function ImageDialog({ imageUrl, open, handleClose }) {
  const largeScreen = useMediaQuery("(min-width:1000px)");
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          padding: largeScreen ? 1 : 0,
          objectFit: "contain",
          display: "block",
          backgroundColor: "white",
        }}
      >
        <Image
          src={imageUrl}
          alt="Uploaded"
          style={{
            maxWidth: "100%",
            maxHeight: "calc(100vh - 200px)",
            display: "block",
          }}
        />
      </Box>
    </Dialog>
  );
}
