import { Outlet } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";

import LeftMenu from "../Components/LeftMenu";
import { UserDataContext } from "../utils/userData";
import Header from "../Components/Header";
import { useEffect, useState } from "react";
import { getCurrentUser, getEvents } from "../utils/api";

export default function LoggedInBasePage() {
  const [user, setUser] = useState(null);
  const [groups, setGroups] = useState(null);
  const [loading, setLoading] = useState(true);
  const largeScreen = useMediaQuery("(min-width:1000px)");
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    Promise.all([
      getCurrentUser().then((res) => Promise.resolve(res.data)),
      getEvents().then((res) => Promise.resolve(res.data)),
    ]).then(([currentUser, groups]) => {
      setUser(currentUser);
      setGroups(groups);
      setLoading(false);
    });
  }, []);

  const reloadUserData = () => {
    Promise.all([
      getCurrentUser().then((res) => Promise.resolve(res.data)),
      getEvents().then((res) => Promise.resolve(res.data)),
    ]).then(([currentUser, groups]) => {
      setUser(currentUser);
      setGroups(groups);
    });
  };

  return (
    <UserDataContext.Provider
      value={{ user, setUser, groups, setGroups, reloadUserData }}
    >
      <Box sx={{ display: "flex" }}>
        <LeftMenu open={drawerOpen} onClose={() => setDrawerOpen(false)} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 0,
            maxWidth: "100%",
          }}
        >
          {!largeScreen && <Header onMenuClick={() => setDrawerOpen(true)} />}
          {!loading && <Outlet />}
        </Box>
      </Box>
    </UserDataContext.Provider>
  );
}
