import { useTheme } from "@emotion/react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Stack,
  Box,
  useMediaQuery,
  Skeleton,
  CardActions,
  Dialog,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageContainer from "../Components/PageContainer";
import { getFeed } from "../utils/api";
import AudioPlayer from "../Components/AudioPlayer";
import Image from "./../Components/Image";

export default function Feed() {
  const largeScreen = useMediaQuery("(min-width:1000px)");
  const smallScreen = useMediaQuery("(max-width:500px)");
  const theme = useTheme();
  const [feedData, setFeedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [imageDialogProps, setImageDialogProps] = useState({
    open: false,
    imageUrl: null,
  });

  useEffect(() => {
    getFeed().then((res) => {
      setFeedData(res.data);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <PageContainer>
        <FakeFeed />
      </PageContainer>
    );
  }

  if (feedData.length === 0) {
    return (
      <PageContainer>
        <Typography>
          Rien de neuf pour le moment ! Crée des groupes, ajoute des potes, et
          échangez vous des points pour que ton fil d'actualité se remplisse !
        </Typography>
      </PageContainer>
    );
  }

  function formatTimeDiff(ts) {
    let diff = moment.duration(moment().diff(moment(ts)));

    if (diff.asDays() >= 1) {
      return Math.round(diff.asDays()) + " j";
    }

    if (diff.asHours() >= 1) {
      return Math.round(diff.asHours()) + " h";
    }

    return Math.round(diff.asMinutes()) + " m";
  }

  return (
    <PageContainer>
      <Grid container spacing={2}>
        {feedData.map((transaction) => (
          <Grid item xs={12} key={transaction.transactionId}>
            <Card sx={{ marginBottom: theme.spacing(3) }}>
              <CardActionArea
                onClick={() => navigate(`/group/${transaction.event.eventId}`)}
              >
                <CardContent>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      marginBottom: theme.spacing(2),
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar>
                      {transaction.from_member.username
                        .slice(0, 2)
                        .toUpperCase()}
                    </Avatar>
                    <div>
                      <Typography
                        sx={{ fontSize: largeScreen ? 16 : 13 }}
                        component="div"
                      >
                        <strong>{transaction.event.name} </strong>
                        {transaction.from_member.username} t'a attribué{" "}
                        {transaction.transaction_amount} points.
                      </Typography>
                      <Typography sx={{ fontSize: 12 }} color="text.secondary">
                        {formatTimeDiff(transaction.timestamp)}
                      </Typography>
                    </div>
                  </Stack>

                  <Box sx={{ marginLeft: theme.spacing(1) }}>
                    <Typography
                      sx={{ fontSize: 18 }}
                      component="p"
                      color="text.secondary"
                    >
                      {transaction.message}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
              {!!transaction.audio && (
                <CardActions>
                  <Box
                    sx={{
                      width: "100%",
                      padding: theme.spacing(1),
                    }}
                  >
                    <AudioPlayer
                      src={transaction.audio}
                      sx={{ maxWidth: smallScreen ? "none" : "300px" }}
                    />
                  </Box>
                </CardActions>
              )}
              {!!transaction.image && (
                <Box
                  sx={{
                    maxHeight: "600px",
                    maxWidth: smallScreen ? "100%" : "50%",
                    margin: theme.spacing(2),
                    border: "1px solid",
                    borderColor: theme.palette.primary.main,
                    overflow: "hidden",
                  }}
                >
                  <Image
                    src={transaction.image}
                    alt="Uploaded"
                    style={{
                      objectFit: "contain",
                      display: "block",
                      maxWidth: "100%",
                    }}
                    onClick={() =>
                      setImageDialogProps({
                        open: true,
                        imageUrl: transaction.image,
                      })
                    }
                  />
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
      <ImageDialog
        {...imageDialogProps}
        handleClose={() => {
          setImageDialogProps({ open: false, imageUrl: null });
        }}
      />
    </PageContainer>
  );
}

function FakeFeed() {
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ marginBottom: theme.spacing(3) }}>
            <CardContent>
              <Stack>
                <Skeleton height={"30px"} />
                <Skeleton height={"30px"} width={"50%"} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ marginBottom: theme.spacing(3) }}>
            <CardContent>
              <Stack>
                <Skeleton height={"30px"} />
                <Skeleton height={"30px"} width={"50%"} />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

function ImageDialog({ imageUrl, open, handleClose }) {
  const largeScreen = useMediaQuery("(min-width:1000px)");
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          padding: largeScreen ? 1 : 0,
          objectFit: "contain",
          display: "block",
          backgroundColor: "white",
        }}
      >
        <Image
          src={imageUrl}
          alt="Uploaded"
          style={{
            maxWidth: "100%",
            maxHeight: "calc(100vh - 200px)",
            display: "block",
          }}
        />
      </Box>
    </Dialog>
  );
}
