import { useEffect, useState } from "react";
import { useAuth } from "../utils/auth";
import { getCurrentUser, updateUser } from "../utils/api";
import {
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Alert,
  LinearProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import PageContainer from "../Components/PageContainer";

export default function Profile() {
  const [user, setUser] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { currentUserId } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    getCurrentUser().then((res) => {
      setUser(res.data);
    });
  }, [currentUserId]);

  const saveNotificationsSettings = () => {
    setSuccess(false);
    setError(false);
    updateUser(currentUserId, { settings: user.settings })
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setError(true);
      });
  };
  if (!user) {
    return <LinearProgress sx={{ height: "3px" }} />;
  }

  return (
    <>
      <PageContainer size={"small"}>
        <Typography variant="h2" style={{ marginTop: theme.spacing(3) }}>
          Informations générales
        </Typography>
        <TextField
          variant="standard"
          label="Nom d'utilisateur :"
          disabled
          value={user.username}
          fullWidth
          style={{ marginTop: theme.spacing(3) }}
        />
        <TextField
          variant="standard"
          label="Adresse e-mail :"
          disabled
          value={user.email}
          fullWidth
          style={{ marginTop: theme.spacing(3) }}
        />
        <Link
          to="/change-password"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Button style={{ marginTop: theme.spacing(3) }} variant="outlined">
            Modifier le mot de passe
          </Button>
        </Link>
        <Typography variant="h2" style={{ marginTop: theme.spacing(3) }}>
          Notifications par e-mail
        </Typography>
        <FormControlLabel
          control={<Switch />}
          checked={user.settings.notifications.email.invited_to_event}
          label="Me prévenir lorsqu'un utilisateur m'a ajouté à un groupe."
          labelPlacement="start"
          sx={{
            display: "flex",
            marginTop: theme.spacing(3),
            justifyContent: "space-between",
          }}
          onChange={(event) => {
            setHasChanged(true);
            setUser((prevValue) => {
              let returnedValue = { ...prevValue };
              returnedValue.settings.notifications.email.invited_to_event =
                event.target.checked;
              return returnedValue;
            });
          }}
        />

        <FormControlLabel
          control={<Switch />}
          checked={user.settings.notifications.email.given_points}
          label="Me prévenir lorsqu'un utilisateur m'a donné ou enlevé des points dans un groupe."
          labelPlacement="start"
          sx={{
            display: "flex",

            marginTop: theme.spacing(3),
            justifyContent: "space-between",
          }}
          onChange={(event) => {
            setHasChanged(true);
            setUser((prevValue) => {
              let returnedValue = { ...prevValue };
              returnedValue.settings.notifications.email.given_points =
                event.target.checked;
              return returnedValue;
            });
          }}
        />
        {hasChanged && (
          <Button
            sx={{
              marginTop: theme.spacing(3),
            }}
            onClick={saveNotificationsSettings}
            variant="outlined"
          >
            Enregistrer
          </Button>
        )}
        {success && (
          <Alert
            sx={{
              marginTop: theme.spacing(3),
            }}
            severity="success"
          >
            Modifications enregistrées.
          </Alert>
        )}
        {error && (
          <Alert
            sx={{
              marginTop: theme.spacing(3),
            }}
            severity="error"
          >
            Désolé, une erreur est survenue.
          </Alert>
        )}
      </PageContainer>
    </>
  );
}
