import { useTheme } from "@emotion/react";
import { DialogContent, Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useRef, useState, forwardRef } from "react";
import { useAuth } from "../utils/auth";
import { givePoints, uploadMedia } from "../utils/api";
import {
  Box,
  Dialog,
  InputLabel,
  Slider,
  TextField,
  IconButton,
  Button,
  Alert,
  Slide,
  DialogTitle,
} from "@mui/material";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import DeleteIcon from "@mui/icons-material/Delete";
import { Mp3MediaRecorder } from "mp3-mediarecorder";
import mp3RecorderWorker from "workerize-loader!./../../src/utils/mp3Worker"; // eslint-disable-line import/no-webpack-loader-syntax
import AudioPlayer from "./AudioPlayer";
import Header from "./Header";
import { UserDataContext } from "../utils/userData";

export default function GivePointsModal({
  open,
  selectedUser,
  eventId,
  onClose,
  onSuccess,
}) {
  const { reloadUserData } = useContext(UserDataContext);
  const smallScreen = useMediaQuery("(max-width:400px)");
  const largeScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const [error, setError] = useState(false);
  const { currentUserId } = useAuth();
  const [numberOfPoints, setNumberOfPoints] = useState(0);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const recorderRef = useRef(null);
  const worker = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingComplete, setRecordingComplete] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [countdown, setCountDown] = useState();
  const countdownInterval = useRef();
  const [selectedFile, setSelectedFile] = useState();

  const supportsWasm =
    WebAssembly && typeof WebAssembly.instantiate === "function";
  const supportsUserMediaAPI =
    navigator.mediaDevices &&
    typeof navigator.mediaDevices.getUserMedia === "function";
  const isAudioRecorderSupported = supportsWasm && supportsUserMediaAPI;

  useEffect(() => {
    worker.current = mp3RecorderWorker();
  }, []);

  const clearAndClose = () => {
    setMessage("");
    setNumberOfPoints(0);
    onClose();
    setAudioBlob(null);

    if (recorderRef.current !== null) {
      recorderRef.current.stop();
      recorderRef.current = null;
    }

    setIsRecording(false);
    setRecordingComplete(false);

    clearInterval(countdownInterval.current);
    countdownInterval.current = null;

    setSelectedFile(null);
  };

  const onFileSelected = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const deleteAudio = () => {
    setAudioBlob(null);
    setRecordingComplete(false);
  };

  const stopRecording = () => {
    if (recorderRef.current !== null) {
      recorderRef.current.stop();
      recorderRef.current = null;
    }
    setCountDown(null);
    setIsRecording(false);
    setRecordingComplete(true);
    clearInterval(countdownInterval.current);
    countdownInterval.current = null;
  };

  const startRecording = () => {
    if (!isRecording) {
      setCountDown(59);
      setIsRecording(true);
      setAudioBlob(null);
      window.navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new Mp3MediaRecorder(stream, {
            worker: worker.current,
          });
          recorder.start();
          recorder.ondataavailable = (event) => {
            setAudioBlob(event.data);
          };
          recorderRef.current = recorder;
          recorder.onstop = () => {
            stream.getTracks().forEach((track) => track.stop());
          };

          countdownInterval.current = setInterval(
            () => setCountDown((value) => Math.max(0, value - 1)),
            1000
          );

          setTimeout(stopRecording, 60000);
        })
        .catch((err) => {
          setIsRecording(false);
          setError(
            "Vous devez donner l'autorisation d'utiliser le microphone pour enregistrer un audio."
          );
        });
    } else {
      stopRecording();
    }
  };

  const onSubmit = () => {
    setLoading(true);

    let hasImage = !!selectedFile;
    let imageType = null;
    if (hasImage) {
      imageType = selectedFile.type.split("/")[1];
    }

    setError(false);
    givePoints(
      eventId,
      currentUserId,
      selectedUser.userId,
      parseInt(numberOfPoints),
      message,
      recordingComplete,
      hasImage,
      imageType
    )
      .then((res) => {
        return Promise.all([
          !!res.data.audio_presigned_url
            ? uploadMedia(audioBlob, res.data.audio_presigned_url)
            : true,
          !!res.data.image_presigned_url
            ? uploadMedia(selectedFile, res.data.image_presigned_url)
            : true,
        ]);
      })
      .then(() => {
        reloadUserData();
        setLoading(false);
        onSuccess();
        clearAndClose();
      })
      .catch((err) => {
        console.log(err);
        let reason = err.response?.data?.reason || "UNKNOWN";

        if (reason === "POINTS_QUOTA_EXCEEDED") {
          setError(
            "Vous avez dépassé la limite journalière de point à allouer.\n" +
              `Nombre de points restants : ${err.response.data.remainingPointsToGive}`
          );
        } else if (reason === "UNKNOWN") {
          setError("Désolé, une erreur est survenue");
        }
      });
  };

  if (selectedUser === null) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={clearAndClose}
        fullScreen={!largeScreen}
        TransitionComponent={Transition}
      >
        {!largeScreen && <Header displayHamburger={false} />}
        <DialogTitle
          component={Typography}
          variant="h1"
          fontWeight={"bold"}
          gutterBottom
          sx={{ padding: "20px" }}
        >
          Attribuer des points à {selectedUser.username}
        </DialogTitle>
        <DialogContent
          sx={{
            width: "500px",
            maxWidth: "100%",
            paddingTop: "10px !important",
          }}
        >
          <InputLabel>Nombre de points ({numberOfPoints})</InputLabel>
          <Slider
            defaultValue={70}
            valueLabelDisplay="auto"
            value={numberOfPoints}
            min={-10}
            max={10}
            onChange={(e) => {
              setNumberOfPoints(e.target.value);
            }}
          />

          <TextField
            variant="standard"
            label="Message :"
            onChange={(e) => {
              setMessage(e.target.value.slice(0, 100));
            }}
            value={message}
            fullWidth
            style={{ marginBottom: theme.spacing(2) }}
            autoComplete="off"
          />

          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {isAudioRecorderSupported && (
              <>
                <IconButton size="large" onClick={startRecording}>
                  <KeyboardVoiceIcon />
                </IconButton>
              </>
            )}
            {isRecording && (
              <Typography>
                {countdown >= 10 ? `0:${countdown}` : `0:0${countdown}`}
              </Typography>
            )}
            {recordingComplete && (
              <IconButton onClick={deleteAudio}>
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>

          {recordingComplete && audioBlob !== null && (
            <div>
              <AudioPlayer src={URL.createObjectURL(audioBlob)} />
            </div>
          )}

          <Button variant="outlined" component="label" sx={{ marginTop: 2 }}>
            Ajouter une Image
            <input
              accept="image/*"
              type="file"
              hidden
              onChange={onFileSelected}
            />
          </Button>

          {!!selectedFile && (
            <Box
              sx={{
                width: smallScreen ? "100%" : "50%",
                height: "202px",
                marginTop: theme.spacing(2),
                border: "1px solid",
                borderColor: theme.palette.primary.main,
              }}
            >
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Uploaded"
                width={"100%"}
                height="200px"
                style={{ objectFit: "cover" }}
              />
            </Box>
          )}

          <div style={{ marginTop: theme.spacing(2) }}>
            <Button
              onClick={onSubmit}
              disabled={numberOfPoints === 0 || loading || isRecording}
            >
              {!loading ? "Attribuer" : "Attribution..."}
            </Button>
            <Button
              color="error"
              onClick={clearAndClose}
              style={{ marginLeft: theme.spacing(2) }}
            >
              Annuler
            </Button>
            {error && (
              <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
                {error}
              </Alert>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
