import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Box,
  Skeleton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPointsSummary } from "../utils/api";
import EventSummaryBarChart from "./Graphs/EventSummaryBarChart";

export default function GroupCard({ group }) {
  const navigate = useNavigate();
  const [points, setPoints] = useState();
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    navigate(`/group/${group.eventId}`);
  };
  useEffect(() => {
    getPointsSummary(group.eventId).then((res) => {
      setPoints(res.data);
      setLoading(false);
    });
  }, [group.eventId]);
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Typography variant="h2" color="text.secondary" gutterBottom>
            {group.name}
          </Typography>
          <Box sx={{ width: "100%", height: "300px" }}>
            {!loading && points.length > 1 && (
              <EventSummaryBarChart pointsData={points} />
            )}
            {!loading && points.length === 1 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography color="text.secondary">
                    Tu es seul dans ce groupe 😢. Invite des potes et échangez
                    vous des points !
                  </Typography>
                </Box>
              </>
            )}
            {loading && (
              <>
                <Skeleton sx={{ height: "30%" }} />
                <Skeleton sx={{ height: "30%", width: "60%" }} />
                <Skeleton sx={{ height: "30%", width: "30%" }} />
              </>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
