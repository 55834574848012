import { create } from "zustand";

const useStore = create((set, get) => ({
  token: null,
  setToken: (token) => set({ token }),
  getToken: () => {
    return get().token;
  },
}));

export default useStore;
