import React, { useEffect, useState } from "react";
import { Box, Tab as MuiTab, Tabs as MuiTabs } from "@mui/material";

function TabPanel({ children, index, value, label }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <>{children}</>}
    </div>
  );
}

function Tabs({ children, defaultSelectedTab, variant }) {
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);

  useEffect(() => {
    const tabPanelType = (<TabPanel />).type;
    children.forEach((child) => {
      if (child.type !== tabPanelType) {
        throw new Error("Tabs can only have TabPanel as children");
      }
    });
  }, [children]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <MuiTabs
          onChange={(event, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          variant={variant}
        >
          {children.map((child) => {
            return (
              <MuiTab
                key={child.props.index}
                label={child.props.label}
                value={child.props.index}
              />
            );
          })}
        </MuiTabs>
      </Box>
      {children.map((child) => {
        return (
          <TabPanel
            key={child.props.index}
            value={selectedTab}
            index={child.props.index}
            sx={{ width: "100%" }}
          >
            {child.props.children}
          </TabPanel>
        );
      })}
    </>
  );
}

export { Tabs, TabPanel };
