import "./App.css";
import { BrowserRouter } from "react-router-dom";

import { AuthContext } from "./utils/auth";
import { useEffect, useState } from "react";
import { getCurrentUser, refreshToken, logout } from "./utils/api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { registerForPush, unregisterPushSubscription } from "./utils/push";
import AppRoutes from "./AppRoutes";
import useStore from "./utils/store";
import { LinearProgress } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const theme = createTheme({
    palette: {
      primary: { main: "#00e676", light: "#66ffa6", dark: "#00b248" },
      secondary: { main: "#ffff00", light: "#ffff5a", dark: "#c7cc00" },
      neutral: {
        main: "#64748B",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "Roboto",
      h1: {
        fontSize: 24,
      },
      h2: {
        fontSize: 20,
      },
      title: {
        fontFamily: "Inter",
        fontWeight: 700,
      },
    },
  });

  const [authenticated, setAuthenticated] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [initialLoading, setInitalLoading] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const token = useStore((state) => state.token);
  const setToken = useStore((state) => state.setToken);

  useEffect(() => {
    if (!token) {
      refreshToken()
        .then((res) => {
          setToken(res.data.token);
        })
        .catch(() => {
          setAuthenticated(false);
          setInitalLoading(true);
          return;
        });
    }
  }, [token, setToken]);

  useEffect(() => {
    if (token) {
      getCurrentUser()
        .then((res) => {
          setAuthenticated(true);
          setCurrentUserId(res.data.userId);
          setEmailConfirmed(res.data.email_confirmed);
          setIsAdmin(res.data.admin);
        })
        .catch(() => {
          setAuthenticated(false);
          setToken(null);
        })
        .finally(() => {
          setInitalLoading(true);
        });
    }
  }, [token, setToken]);

  const updateAuth = (data) => {
    if (data === false) {
      logout().then(() => {
        setToken(null);
        setAuthenticated(false);
        setCurrentUserId(null);
        unregisterPushSubscription();
      });
      return;
    }

    setAuthenticated(true);
    setCurrentUserId(data.userId);
    setEmailConfirmed(data.email_confirmed);
    setToken(data.token);
    setIsAdmin(data.admin || false);
  };

  if (!initialLoading) {
    return (
      <ThemeProvider theme={theme}>
        <LinearProgress sx={{ height: "2px" }} />
      </ThemeProvider>
    );
  }

  if (authenticated) {
    registerForPush();
  }

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AuthContext.Provider
          value={{
            authenticated,
            updateAuth,
            currentUserId,
            emailConfirmed,
            setEmailConfirmed,
            isAdmin,
          }}
        >
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <AppRoutes
                authenticated={authenticated}
                emailConfirmed={emailConfirmed}
                isAdmin={isAdmin}
              />
            </BrowserRouter>
          </ThemeProvider>
        </AuthContext.Provider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
