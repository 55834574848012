import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PageContainer({
  children,
  size,
  background = "white",
}) {
  const theme = useTheme();
  const largeScreen = useMediaQuery("(min-width:600px)");

  if (size === "small") {
    return (
      <Box
        sx={{
          margin: "0 auto",
          padding: theme.spacing(largeScreen ? 4 : 2),
          maxWidth: 800,
          bgcolor: background,
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        margin: "0 auto",
        padding: theme.spacing(largeScreen ? 2 : 2),
        bgcolor: background,
      }}
    >
      {children}
    </Box>
  );
}
