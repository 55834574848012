import React, { useState } from "react";
import { addEventMember, findUser } from "../utils/api";
import {
  Button,
  CircularProgress,
  Modal,
  Box,
  TextField,
  Alert,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import useMediaQuery from "@mui/material/useMediaQuery";

export default function AddMemberModal({ open, eventId, onClose, onSuccess }) {
  const largeScreen = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [lastTimeoutId, setLastTimeoutId] = useState(null);

  const onUsernameValueChange = (e) => {
    setLoadingUser(true);
    let value = e.target.value.toLowerCase();
    setUsername(value);
    setUser(null);

    if (lastTimeoutId !== null) {
      clearTimeout(lastTimeoutId);
    }

    let timeout = setTimeout(() => {
      findUser(value)
        .then((res) => setUser(res.data))
        .catch(() => {
          setUser(null);
        })
        .finally(() => setLoadingUser(false));
    }, 300);
    setLastTimeoutId(timeout);
  };

  const clear = () => {
    setUsername("");
    setUser(null);
    setError(false);
  };

  const clearAndClose = () => {
    setUser(null);
    setUsername("");
    setError(false);
    onClose();
  };

  const addMember = () => {
    setError(false);
    addEventMember(eventId, user.userId)
      .then(() => {
        clear();
        onSuccess();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setError(
            "Vous devez être le créateur du groupe pour ajouter des membres."
          );
        } else {
          setError("Désolé, une erreur est survenue.");
        }
      });
  };

  return (
    <>
      <Modal open={open} onClose={clearAndClose}>
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: largeScreen ? 400 : 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "4px / 4px",
            padding: theme.spacing(3),
          }}
        >
          <Typography variant="h1" gutterBottom fontWeight={"bold"}>
            Ajouter un nouveau membre
          </Typography>
          <TextField
            variant="standard"
            label="Nom d'utilisateur"
            onChange={onUsernameValueChange}
            value={username}
            fullWidth
            style={{ marginBottom: theme.spacing(2) }}
            autoComplete="off"
          />
          {!!user && (
            <>
              <div style={{ verticalAlign: "middle" }}>
                <div>{user.username}</div>

                <div
                  style={{
                    marginTop: theme.spacing(2),
                  }}
                >
                  <Button
                    onClick={addMember}
                    style={{
                      marginTop: largeScreen ? 0 : theme.spacing(1),
                      marginLeft: 0,
                      display: largeScreen ? "inline" : "block",
                    }}
                  >
                    Ajouter
                  </Button>
                  <Button
                    color="error"
                    onClick={() => {
                      clearAndClose();
                    }}
                    style={{
                      marginTop: largeScreen ? 0 : theme.spacing(1),
                      marginLeft: largeScreen ? theme.spacing(1) : 0,
                      display: largeScreen ? "inline" : "block",
                    }}
                  >
                    Annuler
                  </Button>
                </div>
              </div>
            </>
          )}
          {!user && !!username && (
            <>
              <div>
                <span>
                  {loadingUser ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Aucun utilisateur."
                  )}
                </span>
              </div>
            </>
          )}

          {!user && (
            <Button
              color="error"
              onClick={() => {
                clearAndClose();
              }}
              style={{ marginTop: 10 }}
            >
              Annuler
            </Button>
          )}
          {error && (
            <Alert severity="error" style={{ marginTop: theme.spacing(2) }}>
              {error}
            </Alert>
          )}
        </Box>
      </Modal>
    </>
  );
}
