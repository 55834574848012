import { useState } from "react";
import { register } from "./../utils/api";
import { Button, Alert, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../utils/auth";

export default function RegisterForm() {
  const theme = useTheme();
  const { updateAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const defaultRegisterButtonText = "Créer un compte";
  const [registerButtonText, setRegisterButtonText] = useState(
    defaultRegisterButtonText
  );

  const onSubmit = (event) => {
    event.preventDefault();
    setRegisterButtonText("Création...");
    setError("");
    if (!email || !email.match("^(.+)@(.+)$")) {
      setError("Adresse e-mail invalide.");
      setRegisterButtonText(defaultRegisterButtonText);
      return;
    }
    if (!username) {
      setError("Le nom d'utilisateur est requis.");
      setRegisterButtonText(defaultRegisterButtonText);
      return;
    }

    if (!username.match("^[a-z]([a-z0-9_]){3,}$")) {
      setError(
        "Le nom d'utilisateur doit comporter au minimum 3 caractères alphanumériques et commencer par une lettre."
      );
      setRegisterButtonText(defaultRegisterButtonText);
      return;
    }
    if (
      !password ||
      !password.match("^(?=.*[0-9])(?=.*[-!@#$^&*])[a-zA-Z0-9-!@#$^&*]{6,30}$")
    ) {
      setError(
        "Le mot de passe est invalide. Votre mot de passe doit comporter entre 6 et 30 caractères, " +
          "et inclure au moins un chiffre et un caractère spécial (-!@#$^&*)."
      );
      setRegisterButtonText(defaultRegisterButtonText);
      return;
    }
    if (!passwordConfirm || password !== passwordConfirm) {
      setError("Les mots de passe ne correspondent pas.");
      setRegisterButtonText(defaultRegisterButtonText);
      return;
    }
    register(email, username, password)
      .then((res) => {
        updateAuth({ ...res.data, email_confirmed: false });
      })
      .catch((err) => {
        if (err.response.data === "username already in use") {
          setError(
            "Le nom d'utilisateur est déjà utilsé, choisissez-en un autre."
          );
        } else if (err.response.data === "email already registered") {
          setError("L'adresse e-mail saisie est déjà utilisée.");
        } else {
          setError("Désolé, une erreur est survenue.");
        }
        setRegisterButtonText(defaultRegisterButtonText);
      });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextField
          label="Adresse e-mail"
          variant="standard"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          value={email}
          name="email"
          fullWidth
          style={{ marginTop: theme.spacing(2) }}
          autoComplete="off"
        />
        <TextField
          label="Nom d'utilisateur"
          variant="standard"
          onChange={(e) => {
            if (!e.target.value.toLowerCase().match("^[a-z0-9_]*$")) {
              return;
            }
            setUsername(e.target.value.toLowerCase());
          }}
          value={username}
          name="username"
          fullWidth
          style={{ marginTop: theme.spacing(2) }}
          autoComplete="off"
        />
        <TextField
          label="Mot de passe"
          variant="standard"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          fullWidth
          type="password"
          name="password"
          style={{ marginTop: theme.spacing(2) }}
          autoComplete="off"
        />

        <TextField
          label="Confirmer le mot de passe"
          variant="standard"
          onChange={(e) => setPasswordConfirm(e.target.value)}
          value={passwordConfirm}
          fullWidth
          type="password"
          name="passwordConfirm"
          style={{ marginTop: theme.spacing(2) }}
          autoComplete="off"
        />

        <Button
          style={{ marginTop: theme.spacing(2) }}
          type="submit"
          variant="outlined"
        >
          {registerButtonText}
        </Button>
      </form>

      {!!error && (
        <Alert style={{ marginTop: theme.spacing(2) }} severity="error">
          {error}
        </Alert>
      )}
    </>
  );
}
