import { Pause, PlayArrow } from "@mui/icons-material";
import { IconButton, Box, useTheme, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function AudioPlayer({ src, sx }) {
  const audioElementRef = useRef();
  const [durationCountdown, setDurationCountdown] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const countDownInterval = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(null);
  const theme = useTheme();
  const [hasError, setHasError] = useState(true);

  const play = () => {
    setIsPlaying(true);
    audioElementRef.current.play();
    audioElementRef.current.addEventListener("ended", () => {
      setIsPlaying(false);
      clearInterval(countDownInterval.current);
      countDownInterval.current = null;

      if (isFinite(audioElementRef.current.duration)) {
        setDurationCountdown(audioElementRef.current.duration);
        setDuration(audioElementRef.current.duration);
      }
    });

    countDownInterval.current = setInterval(() => {
      if (isFinite(audioElementRef.current.duration)) {
        setDurationCountdown(
          audioElementRef.current.duration - audioElementRef.current.currentTime
        );
      }
      setCurrentTime(audioElementRef.current.currentTime);
    }, 10);
  };

  const pause = () => {
    audioElementRef.current.pause();
    setIsPlaying(false);
    clearInterval(countDownInterval.current);
    countDownInterval.current = null;
  };

  function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  function printDuration(duration) {
    const minutes = Math.floor(duration / 60);
    const seconds = duration - minutes * 60;

    return `${minutes}:${str_pad_left(seconds, "0", 2)}`;
  }

  useEffect(() => {
    if (!!audioElementRef.current) {
      if (
        audioElementRef.current.canPlayType("audio/ogg") === "" &&
        src.split("?")[0].endsWith(".ogg")
      ) {
        return;
      }
      setHasError(false);
      audioElementRef.current.addEventListener("loadedmetadata", () => {
        if (
          !!audioElementRef?.current?.duration &&
          isFinite(audioElementRef?.current?.duration)
        ) {
          setDurationCountdown(audioElementRef.current.duration);
          setDuration(audioElementRef.current.duration);
          setCurrentTime(audioElementRef.current.duration);
        }
      });
    }
  }, [audioElementRef, src]);

  useEffect(() => {
    return () => {
      if (!!countDownInterval.current) {
        clearInterval(countDownInterval.current);
        countDownInterval.current = null;
      }
    };
  }, []);

  return (
    <>
      <audio ref={audioElementRef} src={src} hidden />
      {hasError && (
        <>
          <Typography fontSize={12} color="text.secondary">
            Audio non supporté par le navigateur.
          </Typography>
        </>
      )}
      {!hasError && (
        <>
          <Box
            sx={{
              width: "100%",
              borderRadius: "10px / 10px",
              bgcolor: theme.palette.primary.main,
              padding: 1,
              marginTop: 0,
              ...sx,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {isPlaying ? (
                <IconButton onClick={pause}>
                  <Pause />
                </IconButton>
              ) : (
                <IconButton onClick={play}>
                  <PlayArrow />
                </IconButton>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <ProgressBar totalTime={duration} currentTime={currentTime} />
              </Box>
              {durationCountdown !== null && (
                <Typography
                  sx={{
                    marginLeft: theme.spacing(2),
                    marginRight: theme.spacing(2),
                  }}
                >
                  {printDuration(Math.round(durationCountdown))}
                </Typography>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

function ProgressBar({ totalTime, currentTime }) {
  const theme = useTheme();
  if (!totalTime) {
    return <></>;
  }

  return (
    <Box sx={{ marginLeft: theme.spacing(1) }}>
      <Box
        sx={{
          bgcolor: theme.palette.primary.dark,
          borderRadius: "5px / 5px",
          width: `${(currentTime / totalTime) * 100}%`,
          height: "5px",
        }}
      />
    </Box>
  );
}
