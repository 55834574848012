import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  ChangePassword,
  Admin,
  EmailConfirm,
  EmailNotConfirmed,
  Group,
  GroupSettings,
  Home,
  LandingPage,
  PasswordReset,
  Profile,
  RequestPasswordReset,
} from "./Pages";
import ReactGA from "react-ga4";

import LoggedInBasePage from "./Pages/LoggedInBasePage";
import Feed from "./Pages/Feed";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";

export default function AppRoutes({ authenticated, emailConfirmed, isAdmin }) {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-9CJT5LP8WB");
  }, []);

  useEffect(() => {
    let page = location.pathname + location.search;
    if (location.pathname === "/") {
      page = authenticated ? "/home" : "/landing-page";
    }

    ReactGA.send({ hitType: "pageview", page: page });
  }, [authenticated, location]);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          !authenticated ? (
            <LandingPage />
          ) : emailConfirmed ? (
            <LoggedInBasePage />
          ) : (
            <EmailNotConfirmed />
          )
        }
      >
        {authenticated && emailConfirmed && (
          <>
            <Route path="" element={<Home />} />
            <Route path="profile" element={<Profile />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="/group/:eventId" element={<Group />} />
            <Route path="/feed" element={<Feed />} />

            <Route
              path="/group/:eventId/settings"
              element={<GroupSettings />}
            />
            {isAdmin && <Route exact path="/admin" element={<Admin />} />}
          </>
        )}
      </Route>

      <Route path="/verify-email/:verifyToken" element={<EmailConfirm />} />

      <Route exact path="/reset-password" element={<RequestPasswordReset />} />

      <Route path="/reset-password/:token" element={<PasswordReset />} />
      <Route exact path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
