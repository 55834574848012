import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LineChart,
  Brush,
} from "recharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import COLORS from "../../utils/colors";

export default function EvolutionGraph({ event, pointsData }) {
  const largeScreen = useMediaQuery("(min-width:600px)");
  const [points, setPoints] = useState(null);
  const [numPoints, setNumPoints] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    setNumPoints(pointsData.length);
    let pointsDataSorted = [...pointsData];
    pointsDataSorted = pointsData.sort(
      (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
    );

    let initialTs = moment(event.created_at);
    let tmpPoints = [
      {
        timestamp: initialTs,
        timestampUnix: initialTs.unix(),
        index: 0,
      },
    ];
    let currentPoints = {};
    event.members.forEach((user) => {
      tmpPoints[0][user.username] = 0;
      currentPoints[user.username] = 0;
    });

    pointsDataSorted.forEach((point, index) => {
      let currentTs = moment(point.timestamp);
      event.members.forEach((user) => {
        if (user.username === point.to_member.username) {
          currentPoints[point.to_member.username] =
            currentPoints[point.to_member.username] + point.transaction_amount;
        }
      });
      tmpPoints.push({
        index: index + 1,
        timestamp: currentTs,
        timestampUnix: currentTs.unix(),
        ...currentPoints,
        to_member: point.to_member.username,
        from_member: point.from_member.username,
        message: point.message,
        transaction_amount: point.transaction_amount,
      });
    });

    setPoints(tmpPoints);
  }, [event, pointsData]);

  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      <LineChart
        data={points}
        margin={{ top: 5, right: 5, bottom: 5, left: -20 }}
      >
        {event.members.map((user, index) => (
          <React.Fragment key={user.username}>
            {index > -1 && (
              <Line
                dataKey={user.username}
                stroke={COLORS[index]}
                strokeWidth={largeScreen ? 1.75 : 1.25}
                dot={{ r: largeScreen ? 3 : 1.5 }}
                activeDot={{ r: 5 }}
                // isAnimationActive={false}
              />
            )}
          </React.Fragment>
        ))}

        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="index"
          type="number"
          domain={["auto", "auto"]}
          tick={false}
        />
        <YAxis type="number" />
        <Legend
          wrapperStyle={{
            paddingLeft: "10px",
          }}
        />

        <Tooltip
          cursor={{ stroke: "lightgray", strokeWidth: 1 }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: "none" }}
        />
        <Brush
          dataKey={"index"}
          tickFormatter={() => ""}
          startIndex={Math.max(numPoints - 10, 0)}
          endIndex={numPoints}
          stroke={theme.palette.primary.main}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

function CustomTooltip(props) {
  const largeScreen = useMediaQuery("(min-width:600px)");
  const { active, payload } = props;

  if (!active) {
    return <></>;
  }

  const data = payload[0].payload;

  const initalPoint = !data.from_member;

  const title = !initalPoint
    ? `${data.from_member} a attribué ${data.transaction_amount} points à ${data.to_member}.`
    : "Nombre de points initial : 0";

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: largeScreen ? 10 : 5,
        border: "1px solid #8EC3B0",
        fontSize: largeScreen ? "inherit" : 11,
      }}
    >
      <div>{title}</div>
      {!initalPoint ? (
        <>
          <div>Message : {data.message}</div>
          <div>Date: {data.timestamp.format("DD/MM/YY HH:mm")}</div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
