import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getEvent, getPoints } from "../utils/api";
import useForceUpdate from "../utils/forceUpdate";
import {
  Button,
  LinearProgress,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  useMediaQuery,
} from "@mui/material";

import EvolutionGraph from "../Components/Graphs/EvolutionGraph";
import PointsTable from "../Components/PointsTable";
import GroupHistory from "../Components/GroupHistory";
import PieGraph from "../Components/Graphs/PieGraph";
import { Tabs, TabPanel } from "../Components/Tabs";

import { useTheme } from "@mui/material/styles";

import PageContainer from "../Components/PageContainer";
import GivePointsModal from "../Components/GivePointsModal";
import AddMemberModal from "../Components/AddMemberModal";

function LeaderboardTab({
  event,
  pointsData,
  onAddMemberSuccess,
  onGivePointsSucess,
}) {
  const theme = useTheme();
  const { eventId } = useParams();
  const [givePointsmodalIsOpen, setGivePointsmodalIsOpen] = useState(false);
  const [addMemberModalIsOpen, setAddMemberModalIsOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  return (
    <>
      <AddMemberModal
        open={addMemberModalIsOpen}
        onClose={() => {
          setAddMemberModalIsOpen(false);
        }}
        onSuccess={() => {
          setAddMemberModalIsOpen(false);
          onAddMemberSuccess();
        }}
        eventId={event.eventId}
      />
      <GivePointsModal
        open={givePointsmodalIsOpen}
        eventId={event.eventId}
        selectedUser={selectedMember}
        onClose={() => {
          setGivePointsmodalIsOpen(false);
        }}
        onSuccess={() => {
          setGivePointsmodalIsOpen(false);
          onGivePointsSucess();
        }}
      />
      {event.members.length > 1 && (
        <PointsTable
          event={event}
          pointsData={pointsData}
          onGivePointsClick={(user) => {
            setSelectedMember(user);
            setGivePointsmodalIsOpen(true);
          }}
        />
      )}

      {event.isAdmin && (
        <div
          style={{
            marginTop: theme.spacing(2),
          }}
        >
          {event.members.length < 30 && (
            <Button
              variant="outlined"
              onClick={() => setAddMemberModalIsOpen(true)}
              style={{
                marginRight: theme.spacing(2),
              }}
            >
              Ajouter un membre
            </Button>
          )}
          <Link
            to={`/group/${eventId}/settings`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button
              variant="outlined"
              onClick={() => setAddMemberModalIsOpen(true)}
              color="neutral"
            >
              Paramètres
            </Button>
          </Link>
        </div>
      )}
    </>
  );
}

export default function Group() {
  const largeScreen = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const theme = useTheme();
  const [addMemberModalIsOpen, setAddMemberModalIsOpen] = useState(false);
  const [refresh, forceUpdate] = useForceUpdate();

  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [pointsData, setPointsData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      getEvent(eventId).then((res) => Promise.resolve(res.data)),
      getPoints(eventId).then((res) => Promise.resolve(res.data)),
    ])
      .then(([eventData, pointsData]) => {
        setEvent(eventData);
        setPointsData(pointsData);
        setLoading(false);
      })
      .catch(() => {
        navigate("/");
      });

    return () => {
      setEvent(null);
      setPointsData(null);
      setLoading(true);
    };
  }, [eventId, refresh, navigate]);

  if (loading) {
    return <LinearProgress sx={{ height: "3px" }} />;
  }

  return (
    <>
      <PageContainer>
        {event.members.length > 1 && (
          <Tabs
            defaultSelectedTab={"leaderboard-tab"}
            variant={largeScreen ? "standard" : "fullWidth"}
          >
            <TabPanel index={"leaderboard-tab"} label={"Classement"}>
              <LeaderboardTab
                event={event}
                pointsData={pointsData}
                onAddMemberSuccess={() => {
                  forceUpdate();
                }}
                onGivePointsSucess={() => {
                  forceUpdate();
                }}
              />
            </TabPanel>

            <TabPanel index={"graphs-tab"} label={"Graphes"}>
              <Grid container spacing={2} sx={{ marginTop: theme.spacing(1) }}>
                <Grid item xs={12} lg={6}>
                  <Card>
                    <CardHeader
                      title={
                        <Typography
                          variant="h2"
                          sx={{ marginLeft: theme.spacing(5) }}
                          color="text.secondary"
                        >
                          Évolution dans le temps
                        </Typography>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <div
                        style={{
                          width: "100%",
                          height: 500,
                          display: "inline-block",
                        }}
                      >
                        <EvolutionGraph event={event} pointsData={pointsData} />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Card>
                    <CardHeader
                      title={
                        <Typography
                          variant="h2"
                          sx={{ marginLeft: theme.spacing(5) }}
                          color="text.secondary"
                        >
                          Nombre de points donnés
                        </Typography>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <div
                        style={{
                          width: "100%",
                          height: 500,
                          display: "inline-block",
                        }}
                      >
                        <PieGraph event={event} pointsData={pointsData} />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel index={"history"} label={"Historique"}>
              <GroupHistory event={event} pointsData={pointsData} />
            </TabPanel>
          </Tabs>
        )}

        {event.members.length === 1 && event.isAdmin && (
          <>
            <AddMemberModal
              open={addMemberModalIsOpen}
              onClose={() => {
                setAddMemberModalIsOpen(false);
              }}
              onSuccess={() => {
                setAddMemberModalIsOpen(false);
                forceUpdate();
              }}
              eventId={event.eventId}
            />
            <p>Tu es tout(e) seul(e) dans ce groupe !</p>

            <Button
              variant="outlined"
              onClick={() => setAddMemberModalIsOpen(true)}
              style={{
                marginTop: theme.spacing(2),
                marginRight: theme.spacing(2),
              }}
            >
              Ajouter un membre
            </Button>

            <Link
              to={`/group/${eventId}/settings`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                variant="outlined"
                onClick={() => setAddMemberModalIsOpen(true)}
                style={{
                  marginTop: theme.spacing(2),
                }}
                color="neutral"
              >
                Paramètres
              </Button>
            </Link>
          </>
        )}
      </PageContainer>
    </>
  );
}
