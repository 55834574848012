import {
  Alert,
  Button,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
  Box,
  IconButton,
  Divider,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import PageContainer from "../Components/PageContainer";
import {
  getEvent,
  deleteEvent,
  updateEvent,
  deleteEventMember,
  updateEventMember,
} from "../utils/api";
import { UserDataContext } from "../utils/userData";
import SettingsIcon from "@mui/icons-material/Settings";
import useForceUpdate from "../utils/forceUpdate";
import { Delete } from "@mui/icons-material";
import { useAuth } from "../utils/auth";

export default function GroupSettings() {
  const { reloadUserData } = useContext(UserDataContext);
  const { currentUserId } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const [deletionButtonActive, setDeletionButtonActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [event, setEvent] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const { eventId } = useParams();
  const [hasChanged, setHasChanged] = useState(false);
  const [refresh, forceUpdate] = useForceUpdate();
  const [leaveGroupDialogIsOpen, setLeaveGroupDialogIsOpen] = useState(false);
  const [deleteMemberDialogIsOpen, setDeleteMemberDialogIsOpen] =
    useState(false);
  const [selectedMember, setSelectedMember] = useState(false);

  const [formData, setFormData] = useState();

  useEffect(() => {
    getEvent(eventId)
      .then((res) => {
        setEvent(res.data);
        setFormData({ name: res.data.name });
        setLoading(false);
      })
      .catch(() => {
        window.location.href = "/";
      });
  }, [eventId, refresh]);

  const handleChange = (e) => {
    setHasChanged(true);

    setFormData((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const onUpdateEvent = () => {
    setSuccessMessage(false);
    setError(false);
    updateEvent(eventId, formData)
      .then(() => {
        setSuccessMessage("Modifications enregistrées.");
        setError(false);
        forceUpdate();
        reloadUserData();
        setHasChanged(false);
      })
      .catch(() => {
        setError("Désolé, une erreur est survenue.");
      });
  };

  const onDeleteEvent = () => {
    setDeletionButtonActive(false);
    deleteEvent(eventId)
      .then(() => {
        reloadUserData();
        navigate("/");
      })
      .then(() => {
        setDeletionButtonActive(true);
        setError("Désolé, une erreur est survenue.");
      });
  };

  if (loading) {
    return <LinearProgress sx={{ height: "3px" }} />;
  }

  if (!event.isAdmin) {
    return <Navigate to={`/event/${event.eventId}`} />;
  }

  return (
    <PageContainer size={"small"}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography color="text.secondary">
          <SettingsIcon
            sx={{ marginRight: theme.spacing(2) }}
            fontSize="large"
          />
        </Typography>
        <Typography
          variant="h1"
          sx={{ verticalAlign: "middle" }}
          color="text.secondary"
        >
          {event.name}
        </Typography>
      </div>

      <form onChange={handleChange}>
        <TextField
          variant="standard"
          fullWidth
          label="Nom de l'événement"
          name="name"
          value={formData.name}
          autoComplete="off"
        />
      </form>

      <div style={{ marginTop: theme.spacing(2) }}>
        <Button
          variant="outlined"
          onClick={onUpdateEvent}
          sx={{ marginRight: theme.spacing(2) }}
          disabled={!hasChanged || !deletionButtonActive}
        >
          Enregistrer
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={onDeleteEvent}
          disabled={!deletionButtonActive}
        >
          {deletionButtonActive ? "Supprimer le groupe" : "Suppression..."}
        </Button>
      </div>

      <div style={{ marginTop: theme.spacing(2) }}>
        {error && <Alert severity="error">{error}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
      </div>

      <Box sx={{ marginTop: theme.spacing(4) }}>
        <Typography variant="h2" sx={{ marginBottom: theme.spacing(4) }}>
          Membres :
        </Typography>
        <Divider />
        {event.members.map((member) => (
          <React.Fragment key={member.userId}>
            <Box
              sx={{
                padding: theme.spacing(2),
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {member.userId === currentUserId ? (
                <>
                  <Typography color={"text.secondary"}>
                    {member.username}
                  </Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setLeaveGroupDialogIsOpen(true)}
                  >
                    Quitter le groupe
                  </Button>
                </>
              ) : (
                <>
                  <Typography color={"text.secondary"}>
                    {member.username}
                  </Typography>
                  <div style={{ flexGrow: 1 }} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={member.isAdmin}
                        onClick={(e) => {
                          updateEventMember(
                            event.eventId,
                            member.userId,
                            e.target.checked
                          ).then(() => {
                            forceUpdate();
                          });
                        }}
                      />
                    }
                    label="Admin :"
                    labelPlacement="start"
                  />
                  <IconButton
                    onClick={() => {
                      setSelectedMember(member);
                      setDeleteMemberDialogIsOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </>
              )}
            </Box>
            <Divider />
          </React.Fragment>
        ))}
      </Box>

      <DeleteMembrerDialog
        open={deleteMemberDialogIsOpen}
        handleClose={() => {
          forceUpdate();
          setDeleteMemberDialogIsOpen(false);
        }}
        group={event}
        member={selectedMember}
      />
      <LeaveGroupDialog
        open={leaveGroupDialogIsOpen}
        handleClose={() => {
          setLeaveGroupDialogIsOpen(false);
        }}
      />
    </PageContainer>
  );
}

function DeleteMembrerDialog({ open, handleClose, group, member }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const deleteMemeber = () => {
    setLoading(true);
    deleteEventMember(group.eventId, member.userId)
      .then(() => {
        handleClose();
      })
      .catch(() => {
        setError("Désolé, une erreur est survenue.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setError(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Supprimer {member.username} du groupe ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          L'intégralité des points attribués par et à {member.username} seront
          définitvement supprimés.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={deleteMemeber}
          variant="outlined"
          color="error"
          disabled={loading}
        >
          {loading ? "Suppression..." : "Supprimer"}
        </Button>
        <Button
          onClick={() => {
            handleClose();
            setError(false);
          }}
          variant="outlined"
          autoFocus
          disabled={loading}
        >
          Annuler
        </Button>
      </DialogActions>

      {error && (
        <DialogContent>
          <Alert severity="error">{error}</Alert>
        </DialogContent>
      )}
    </Dialog>
  );
}

function LeaveGroupDialog({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Pourquoi vouloir quitter le groupe ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Si tes potes ont été vilains avec toi, tu peux leur enlever des points
          !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" autoFocus>
          Reste avec tes Potes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
