import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { emailConfirm } from "../utils/api";
import { useAuth } from "../utils/auth";
import PageContainer from "../Components/PageContainer";
import { Typography } from "@mui/material";
import Header from "../Components/Header";

export default function EmailConfirm() {
  const navigate = useNavigate();
  const { authenticated, setEmailConfirmed } = useAuth();
  const { verifyToken } = useParams();
  const [success, setSuccess] = useState();

  useEffect(() => {
    emailConfirm(verifyToken)
      .then(() => {
        setSuccess(true);
        if (authenticated) {
          setEmailConfirmed(true);
        }
        navigate("/");
      })
      .catch(() => {
        setSuccess(false);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      });
  });

  if (success === false) {
    return (
      <>
        <Header />
        <PageContainer>
          <Typography variant="p">
            Désolé, nous n'avons pas pu vérifier votre adresse email.
          </Typography>
        </PageContainer>
      </>
    );
  }

  return <></>;
}
