import moment from "moment";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useAuth } from "../utils/auth";

import { styled } from "@mui/material/styles";

export default function PointsTable({ event, pointsData, onGivePointsClick }) {
  const largeScreen = useMediaQuery("(min-width:600px)");
  const { currentUserId } = useAuth();
  const [points, setPoints] = useState(null);

  useEffect(() => {
    let pointsDataSorted = [...pointsData];
    pointsDataSorted = pointsData.sort(
      (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf()
    );

    let currentPoints = {};
    event.members.forEach((user) => {
      currentPoints[user.userId] = {
        userId: user.userId,
        username: user.username,
        points: 0,
      };
    });

    pointsDataSorted.forEach((point) => {
      currentPoints[point.to_member.userId].points =
        currentPoints[point.to_member.userId].points + point.transaction_amount;
    });

    let currentPointsSorted = Object.values(currentPoints);

    currentPointsSorted = currentPointsSorted.sort(
      (a, b) => b.points - a.points
    );

    setPoints(currentPointsSorted);
  }, [event, pointsData]);

  if (points === null) {
    return <></>;
  }

  return (
    <TableContainer
      sx={{
        maxWidth: 900,
      }}
    >
      <Table aria-label="points table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Membre</StyledTableCell>
            <StyledTableCell align="right">Points</StyledTableCell>
            <StyledTableCell align="right">
              Attribuer des Points
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {points.map((user) => (
            <TableRow
              key={user.userId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {user.username}
              </StyledTableCell>
              <StyledTableCell align="right">{user.points}</StyledTableCell>
              <StyledTableCell align="right">
                {user.userId !== currentUserId && (
                  <>
                    <Button
                      onClick={() => onGivePointsClick(user)}
                      size={largeScreen ? "medium" : "small"}
                    >
                      Attribuer des points
                    </Button>
                  </>
                )}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {},
}));
